import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Style.module.scss";
import SEO from "../components/SEO";
import { fhc, logo, fhc_mobile } from "../utils/images";

const Home = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial value on mount
    setIsMobile(window.innerWidth < 768);

    // Add event listener for resizing
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleForm = () => {
    navigate(
      "/answer-some-questions-and-get-to-know-about-your-personal-finance"
    );
  };

  const goToWebsite = () => {
    window.open("https://www.getladda.com", "_blank");
  };

  return (
    <>
      <SEO
        title="Ladda Financial Check"
        description="Ladda Financial Check"
        name="Ladda ng"
        type="article"
      />

      <div className={styles.container}>
        <div className={styles.parentDiv}>
          <div className={styles.logo} onClick={goToWebsite}>
            <img src={logo} alt="Logo" width="100%" height="100%" />
          </div>

          <div className={styles.div1}>
            <div className={styles.div1left}>
              <h1 className={styles.header}>
                Financial
                <br /> Health Check
              </h1>
              <p className={styles.text}>
                Answer a few questions to help you better understand how you
                manage your money and stay on track to meet your financial
                goals.
              </p>

              <div className={styles.button1} onClick={handleForm}>
                Let's Go
              </div>
            </div>

            <div className={styles.div1right}>
              <img
                src={isMobile ? fhc_mobile : fhc}
                alt="Financial Health Check"
                className={styles.image}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
