import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Style.module.scss";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Divider } from "@mui/material";
import { useCallback } from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = typeof window !== "undefined" ? window.location.origin : "";
  // const baseUrl = window.location.protocol + "//" + window.location.host;

  const shareUrl = baseUrl;
  const score = location?.state?.resultData?.score;
  const categoryName = location?.state?.resultData?.category?.name;
  const categoryDesc = location?.state?.resultData?.category?.description;
  const bgStyle = {
    backgroundColor: "#f1f1f1",
    borderRadius: "5px",
  };


  const handleClose = useCallback(() => {
    navigate("/");
  }, [navigate]);
  

  return (
    <>
      <div className={styles.container3}>
        <div className={styles.parentDiv3}>
          <div className={styles.close} onClick={handleClose}>
            <DisabledByDefaultOutlinedIcon fontSize="large" />
          </div>

          <div className={styles.successHeader}>
            FINANCIAL HEALTH CHECK RESULT
          </div>
          <div className={styles.score}>{score}%</div>
          <div className={styles.scoreSubscript}>Your score</div>
          <div className={styles.category}>{categoryName}</div>
          <div className={styles.categoryDesc}>{categoryDesc}</div>
          <Divider />

          <div className={styles.mail}>
            Check your mail for a detailed
            <br /> breakdown of your result
          </div>
          <Divider />

          <div className={styles.socialMediaDiv}>
            <div className={styles.socialMediaText}>Share result on</div>

            <div className={styles.socialMedia}>
              <div className={styles.socialMediaIcon}>
                {" "}
                <TwitterShareButton
                  url={shareUrl}
                  title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  via="getladda"
                  openShareDialogOnClick={true}
                >
                  <TwitterIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                
                  />
                </TwitterShareButton>{" "}
              </div>
              <div className={styles.socialMediaIcon}>
                {" "}
                <LinkedinShareButton
                  url={shareUrl}
                  title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  summary={shareUrl}
                >
                  <LinkedinIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                  />
                </LinkedinShareButton>
              </div>
              <div className={styles.socialMediaIcon}>
                {" "}
                <FacebookShareButton
                  url={shareUrl}
                  quote={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  hashtag={"#FinancialHealthCheckSurvey"}
                >
                  <FacebookIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                  />
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
