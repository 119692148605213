import React from "react";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import {Box, Divider, Modal} from "@mui/material";
import styles from "./Style.module.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  background: "#232323",
  borderRadius: "20px",
  color: "red",
  p: 4,
  overflow: "auto",
};

interface ErrorModalProps {
  modalOpen: boolean;
  errorData?: any; 
  handleClose: () => void;
}


const ErrorModal: React.FC<ErrorModalProps> = ({ modalOpen, errorData, handleClose }) => {
  const handleModalClose = () => {
    handleClose();
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "8px",
          border: "2px solid #c1c1c1",
        }}
      >
        <Box sx={style}>
          <div className={styles.alignCenter}>
            <div className={styles.close} onClick={handleModalClose}>
              <DisabledByDefaultOutlinedIcon fontSize="large" />
            </div>

            <div className={styles.successHeader}>ERROR</div>
            <div className={styles.m1}>
              <Divider style={{ backgroundColor: "red" }}/>
            </div>
            <div className={styles.error}>
              Sorry, an error just occured <br />
              {errorData}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ErrorModal;
