/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Step,
  Stepper,
  StepLabel,
  StepIconProps,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./Style.module.scss";
import axios from "axios";
import { gender } from "../utils/gender";
import { yesorNo } from "../utils/yesorNo";
import { jobType } from "../utils/jobType";
import { dependants } from "../utils/dependants";
import { currencies } from "../utils/currencies";
import { makeStyles } from "@material-ui/core";
import SEO from "../components/SEO";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { BootstrapButton } from "../components/CustomizedItems";
import { CssTextField, CssSelect } from "../components/CustomizedItems";
import { NumericFormatCustom } from "../components/CustomizedItems";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    fontSize: 20,
  },
});

const CustomStepIcon = (props: StepIconProps) => {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div className={classes.root}>
      {/* {completed ? "✔️" : active ? "👉" : "⬜️"} */}
      {completed ? "✅" : active ? "👉" : "⬜️"}
    </div>
  );
};
const getSteps = () => {
  return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"];
};

export default function StepForm() {
  <SEO
    title="Ladda Financial Check"
    description="Ladda Financial Check"
    name="Ladda ng"
    type="article"
  />;

  // const [activeStep, setActiveStep] = useState(0);
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
  );
  const steps = getSteps();
  const [isInputValid, setIsInputValid] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledForward, setDisabledForward] = useState(true);
  const isLastStep = activeStep === steps.length - 1;

  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleCloseError = () => setModalError(false);
  const [modalData, setModalData] = useState(null);

  const [formValue, setformValue] = useState({
    name: "",
    gender: "",
    email: "",
    job_type: "",
    job_security: "",
    dependants: "",
    currency: "NGN",
    living_expense: "",
    save_consistently: "",
    assets_investments: "",
    emergency_funds: "",
    medical_insurance: "",
    pension_scheme: "",
    longterm_portfolio: "",
  });

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // prevent page reload
    }
  };

  const handleEmailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");

    if (!reg.test(e.target.value) || e.target.value === "") {
      setIsInputValid(false);
      setDisabled(true);
    } else if (reg.test(e.target.value) && e.target.value != "") {
      setDisabled(false);
    }

    setIsInputValid(reg.test(e.target.value));
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleNameValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^[A-Za-z\s-]+$/;

    if (!reg.test(e.target.value) || e.target.value === "") {
      setIsInputValid(false);
      setDisabled(true);
    } else if (reg.test(e.target.value) && e.target.value != "") {
      setDisabled(false);
    }

    setIsInputValid(reg.test(e.target.value));
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleExpenseValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(false);
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setIsInputValid(false);
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleDisable = () => {
    if (
      formValue.name.length <= 0 ||
      formValue.gender.length <= 0 ||
      formValue.email.length <= 0 ||
      formValue.job_type.length <= 0 ||
      formValue.job_security.length <= 0 ||
      formValue.dependants.length <= 0 ||
      formValue.living_expense.length <= 0 ||
      formValue.save_consistently.length <= 0 ||
      formValue.assets_investments.length <= 0 ||
      formValue.emergency_funds.length <= 0 ||
      formValue.medical_insurance.length <= 0 ||
      formValue.pension_scheme.length <= 0 ||
      formValue.longterm_portfolio.length <= 0
    ) {
      setDisabled(true);
    } else if (isInputValid) {
      setDisabled(false);
    } else if (!isInputValid) {
      setDisabled(true);
    } else setDisabled(false);
  };

  const handleNext = () => {
    if (isLastStep) {
      handleSubmit();
    } else {
      const numberValue: number = activeStep + 1;
      localStorage.setItem("numberKey", numberValue.toString());
      setActiveStep(activeStep + 1);
      handleDisable();
    }
  };

  const handleForward = () => {
    const storedNumberValue: number = parseInt(
      localStorage.getItem("numberKey") || "0"
    );
    if (formValue.name.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.gender.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.email.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.job_type.length <= 0) {
      setDisabledForward(true);
    }

    if (formValue.job_security.length <= 0) {
      setDisabledForward(true);
    }

    if (formValue.dependants.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.living_expense.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.save_consistently.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.assets_investments.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.emergency_funds.length <= 0) {
      setDisabledForward(true);
    }
    if (formValue.medical_insurance.length <= 0) {
      setDisabledForward(true);
    }

    if (formValue.longterm_portfolio.length <= 0) {
      setDisabledForward(true);
    }
    if (storedNumberValue == activeStep) {
      setDisabledForward(true);
    } else {
      setDisabledForward(false);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDisabledForward(false);
  };

  useEffect(() => {
    handleDisable();
  }, [activeStep]);

  const updateMedia = () => {
    setMobile(window.innerWidth < 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div>
            <p className={styles.center}>
              Before you begin your financial health check provide a few details
              about yourself.
            </p>

            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    disabled
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>
              <div className={styles.flex}>
                <div className={styles.number}>
                  1 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Let’s start with your
                  <span className={styles.bold}> full name.</span>
                  <br />
                  <CssTextField
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.name}
                    id="name"
                    name="name"
                    onChange={handleNameValidation}
                    placeholder="Enter your first name and last name"
                    error={!isInputValid && formValue.name.length != 0}
                    helperText={
                      !isInputValid && formValue.name.length != 0 ? (
                        <Typography variant="h6">
                          Please enter only text
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <p className={styles.center}>
              Before you begin your financial health check, <br />
              provide a few details about yourself.
            </p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  2 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  What is your
                  <span className={styles.bold}> gender?</span>
                  <br />
                  <CssTextField
                    select
                    value={formValue.gender}
                    placeholder="Select an option"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    variant="standard"
                    id="gender"
                    name="gender"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {gender.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <p className={styles.center}>
              Before you begin your financial health check, <br />
              provide a few details about yourself.
            </p>

            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  3 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  What is your
                  <span className={styles.bold}> email address?</span>
                  <br />
                  <CssTextField
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.email}
                    id="email"
                    name="email"
                    onChange={handleEmailValidation}
                    error={!isInputValid && formValue.email.length != 0}
                    helperText={
                      !isInputValid && formValue.email.length != 0 ? (
                        <Typography variant="h6">
                          Please enter a valid email
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                    placeholder="Enter your email address"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  4 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  What type of
                  <span className={styles.bold}> job </span>are you in?
                  <br />
                  <span className={styles.subText}>
                    If you are in employment where health insurance and pension
                    is provided, that is formal employment.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.job_type}
                    id="standard-basic"
                    name="job_type"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {jobType.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  5 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Does your job have
                  <span className={styles.bold}> long-term security?</span>
                  <br />
                  <span className={styles.subText}>
                    If you are self-employed, select no. If formally employed as
                    described previously, select yes.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    value={formValue.job_security}
                    required
                    fullWidth
                    id="standard-basic"
                    name="job_security"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  6 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  How many
                  <span className={styles.bold}> dependants </span> do you have?
                  <br />
                  <span className={styles.subText}>
                    This refers to the number of people you are financially
                    responsible for.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    id="standard-basic"
                    name="dependants"
                    value={formValue.dependants}
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {dependants.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 6:
        return (
          <div>
            <p className={styles.center}>Review your personal finance</p>

            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  7 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  What is your
                  <span className={styles.bold}> living expense</span> every
                  month?
                  <br />
                  <span className={styles.subText}>
                    This is the amount you spend every month on repeat expenses
                    (food, utility bills etc). Type it without a comma.
                  </span>
                  <br />
                  <div className={styles.divflexColumn}>
                    {/* Currency Dropdown */}
                    <CssSelect
                      value={formValue.currency}
                      onChange={(e) =>
                        setformValue({
                          ...formValue,
                          currency: e.target.value as string,
                        })
                      }
                      displayEmpty
                      sx={{ minWidth: 80, marginRight: 1 }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 90,
                            overflowY: "auto",
                          },
                        },
                      }}
                    >
                      {currencies.map((currency) => (
                        <MenuItem key={currency.code} value={currency.code}>
                          {currency.label} {currency.code}
                        </MenuItem>
                      ))}
                    </CssSelect>
                    {/* Amount Input */}
                    <CssTextField
                      variant="standard"
                      margin="normal"
                      type="text"
                      required
                      fullWidth
                      value={formValue.living_expense}
                      id="living_expense"
                      name="living_expense"
                      onChange={handleExpenseValidation}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        inputProps: { currencyPrefix: formValue.currency }, // ✅ Pass selected currency
                      }}
                      placeholder="Enter your living expense"
                      error={
                        parseInt(formValue.living_expense) <= 0 ||
                        parseInt(formValue.living_expense) > 5000000
                      }
                      helperText={
                        parseInt(formValue.living_expense) === 0 ? (
                          <Typography variant="h6">
                            Amounts can not be 0
                          </Typography>
                        ) : (
                          <Typography variant="h6">
                            Living expense can not be more than{" "}
                            {formValue.currency} 5,000,000
                          </Typography>
                        )
                      }
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 7:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  8 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Do you save
                  <span className={styles.bold}>
                    {" "}
                    at least 20% of your income{" "}
                  </span>
                  consistently?
                  <br />
                  <span className={styles.subText}>
                    As an example, if you earn {formValue.currency} 100,000 and
                    you save at least {formValue.currency} 20,000 every month,
                    select yes.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.save_consistently}
                    id="standard-basic"
                    name="save_consistently"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 8:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  9 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Do you
                  <span className={styles.bold}>
                    {" "}
                    invest the money saved in assets{" "}
                  </span>
                  such as bonds, real estate, stocks, crypto?
                  <br />
                  <span className={styles.subText}>
                    If you only keep the money you save in a bank or piggybank,
                    select no.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    value={formValue.assets_investments}
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    id="standard-basic"
                    name="assets_investments"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 9:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  10 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Do you have an
                  <span className={styles.bold}> emergency fund </span>
                  that can cover at least 3 months expenses?
                  <br />
                  <span className={styles.subText}>
                    If you earn no income for the next 3 months, will you be
                    able to provide for yourself without borrowing money or
                    selling your investments (tbills, bonds, real estate,
                    stocks, crypto)?
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    value={formValue.emergency_funds}
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    id="standard-basic"
                    name="emergency_funds"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 10:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  11 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Do you have a
                  <span className={styles.bold}> medical insurance cover?</span>
                  <br />
                  <span className={styles.subText}>
                    Select yes if your employer provides you with health
                    insurance or you bought one for yourself.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.medical_insurance}
                    id="standard-basic"
                    name="medical_insurance"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 11:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleForward}
                    disabled={disabledForward}
                  >
                    <ArrowForwardIosIcon />
                  </BootstrapButton>
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  12 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Are you
                  <span className={styles.bold}>
                    {" "}
                    enrolled in a pension scheme{" "}
                  </span>
                  by your employer?
                  <br />
                  <span className={styles.subText}>
                    Select yes if your employer makes pension contributions on
                    your behalf.
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.pension_scheme}
                    id="standard-basic"
                    name="pension_scheme"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );

      case 12:
        return (
          <div>
            <p className={styles.center}>Review your personal finance.</p>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.backButton}>
                  <BootstrapButton
                    color="primary"
                    sx={{
                      width: "40px",
                      minWidth: 0,
                      height: "30px",
                    }}
                    onClick={handleBack}
                  >
                    <ArrowBackIosNewIcon />
                  </BootstrapButton>
                </div>

                <div className={styles.forwardButton}></div>
              </div>

              <div className={styles.flex}>
                <div className={styles.number}>
                  13 <ArrowRightAltIcon />
                </div>
                <div className={styles.numberText}>
                  Lastly, do you have a
                  <span className={styles.bold}> long-term portfolio </span>
                  that is dedicated for your future?
                  <br />
                  <span className={styles.subText}>
                    If you have a long-term investments portfolio to cater for
                    you in the future, select yes
                  </span>
                  <br />
                  <CssTextField
                    select
                    size="medium"
                    variant="standard"
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    value={formValue.longterm_portfolio}
                    id="standard-basic"
                    name="longterm_portfolio"
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 20 },
                    }}
                  >
                    {yesorNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontSize: 16 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <div>Not Found</div>;
    }
  }

  const handleSubmit = async () => {
    localStorage.clear();
    let obj = { ...formValue };
    if (typeof formValue.job_security === "string") {
      obj.job_security = JSON.parse(formValue.job_security);
    }
    if (typeof formValue.save_consistently === "string") {
      obj.save_consistently = JSON.parse(formValue.save_consistently);
    }
    if (typeof formValue.assets_investments === "string") {
      obj.assets_investments = JSON.parse(formValue.assets_investments);
    }
    if (typeof formValue.emergency_funds === "string") {
      obj.emergency_funds = JSON.parse(formValue.emergency_funds);
    }
    if (typeof formValue.medical_insurance === "string") {
      obj.medical_insurance = JSON.parse(formValue.medical_insurance);
    }
    if (typeof formValue.pension_scheme === "string") {
      obj.pension_scheme = JSON.parse(formValue.pension_scheme);
    }
    if (typeof formValue.longterm_portfolio === "string") {
      obj.longterm_portfolio = JSON.parse(formValue.longterm_portfolio);
    }
    await axios
      .post("https://panda.getladda.com/api/financial-health-check", obj)
      .then((res) => {
        setShowModal(true);
        setModalData(res.data);
      })
      .catch((error) => {
        setModalData(error.data);
        setModalError(true);
      });
  };

  return (
    <>
      {showModal && (
        <SuccessModal
          modalOpen={showModal}
          resultData={modalData || {}}
          handleClose={handleClose}
        />
      )}

      {modalError && (
        <ErrorModal
          modalOpen={modalError}
          errorData={modalData || {}}
          handleClose={handleCloseError}
        />
      )}

      <div className={styles.container2}>
        <div className={styles.parentDiv2}>
          <div className={styles.stepper}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={CustomStepIcon}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          <div>
            <form>{getStepContent(activeStep)}</form>
            <div className={styles.buttonDiv}>
              {disabled ? (
                <BootstrapButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled
                  endIcon={<DoneIcon />}
                  sx={{
                    fontFamily: "Lexend",
                    fontStyle: "normal",
                    fontWeight: isMobile ? 700 : 500,
                    fontSize: isMobile ? "14px" : "20px",
                    width: "auto",
                    height: "auto",
                    padding: isLastStep ? "12px" : "6px 12px",
                  }}
                  type="submit"
                >
                  {isLastStep ? "Assess your financial health" : "OK"}
                </BootstrapButton>
              ) : (
                <BootstrapButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  endIcon={<DoneIcon />}
                  sx={{
                    fontFamily: "Lexend",
                    fontStyle: "normal",
                    fontWeight: isMobile ? 700 : 500,
                    fontSize: isMobile ? "14px" : "20px",
                    width: "auto",
                    height: "auto",
                    padding: isLastStep ? "12px" : "6px 12px",
                  }}
                  type="submit"
                >
                  {isLastStep ? "Assess your financial health" : "OK"}
                </BootstrapButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
